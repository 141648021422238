// import Vue from 'vue'
// import Router from 'vue-router'
import { createRouter,createWebHashHistory} from "vue-router";

// Vue.use(Router)
const screenShow = () => import('@/views/screenShow')
export const routes = [
    {
        path: '/',
        redirect: '/screen-show',
        component: screenShow,
        meta: { title: '服装（童装）产业大脑数据仓', icon: 'dashboard', affix: true },
        children: [
            {
                path: 'screen-show',
                component: screenShow,
                name: 'screenShow'
            }
        ]
    }
]
export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  })
export default router